import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';

import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { Error404Component } from './error404/error404.component';

/* Use this routes definition in case you want to make them lazy-loaded */
// const routes: Routes = [
//     { path: 'login', component: LoginComponent },
//     { path: 'register', component: RegisterComponent },

//     { path: '404', component: Error404Component },
// ];

@NgModule({
    imports: [
        SharedModule,
        HttpModule
        // RouterModule.forChild(routes)
    ],
    declarations: [
        LoginComponent,
        RegisterComponent,

        Error404Component
    ],
    exports: [
        RouterModule,
        LoginComponent,
        RegisterComponent,

        Error404Component
    ]
})
export class AuthModule { }
