import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';

import { SettingsService } from '../../../core/settings/settings.service';
import { UserAuthService } from 'src/app/service/user-auth.service'

import { RegisterComponent } from '../register/register.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    valForm: FormGroup;

    constructor(public settings: SettingsService,
                private fb: FormBuilder,
                private _userAuthService: UserAuthService,
                private _router: Router,
                private modalRef: BsModalRef,
                private modalService: BsModalService) {

        this.valForm = fb.group({
            'email': [null, Validators.compose([Validators.required, CustomValidators.email])],
            'password': [null, Validators.required]
        });
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        if (this.valForm.valid) {
            console.log('Valid!');
            console.log(value);

            this._userAuthService.login(value.email, value.password)
            .then(() => {
                this.modalRef.hide();
                this._router.navigate(['home']);
            })
            .catch((error) => {
                this.modalRef.hide();
            })
        }
    }

    onRegister() {
        this.modalRef.hide();
        this.modalRef = this.modalService.show(RegisterComponent,  {
            initialState: {
                title: 'Register'
            }
        });
        // this._router.navigate(['/register']);
    }

    ngOnInit() {

    }
}
