import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';

import { User } from 'firebase';

import { FBUserModel } from 'src/app/model/user'
import { COLLECTION_USERS } from 'src/app/model/constants';
import { AppService } from './app.service';
import { reject } from 'lodash';

@Injectable({
    providedIn:  'root'
})
export class UserAuthService{

    fbUser: FBUserModel;
    authUser: User;

    onUserInfoChanged: BehaviorSubject<any>;

    dbRef: any = null;

    public redirectUrl: string;

    constructor(
        private _afAuth: AngularFireAuth, 
        private _db: AngularFireDatabase,
        private _appService: AppService
    ) {
        this.dbRef = _db.database.ref()
        this.onUserInfoChanged = new BehaviorSubject([]);

        this.fbUser = new FBUserModel('', {});

        this._afAuth.authState.subscribe((user) => {
            if (user){
                this.authUser = user;
                localStorage.setItem('authUser', JSON.stringify(this.authUser));
            } else {
                localStorage.setItem('authUser', null);
            }
        });

        // load local storage
        this.loadHLUserFromLocal();
    }

    async login(email: string, password: string): Promise<any> {
        // this._progressBarService.beginLoading2(); 
        return new Promise<any> ((resolve, reject) => {
            this._afAuth.auth.signInWithEmailAndPassword(email, password)
            .then(authUser => {
                const fbUser = {
                    uid: authUser.user.uid,
                    email: authUser.user.email
                };
                console.log("auth user", fbUser)

                this.getUser(authUser.user.uid)
                .then(fbUser => {
                    if (fbUser !== false){
                        this.updatedUser(fbUser); 
                        resolve(fbUser);
                        // this._progressBarService.endLoading2();
                    }
                    else{
                        resolve(false);
                        // this._progressBarService.endLoading2();
                    }
                })
            }, reject)
            .catch((error) => {
            });
        });
    }

    async logout(): Promise<any> {
        this._afAuth.auth.signOut().then(function() {
            localStorage.removeItem('fbUser');
            // this._appService.curUser = null;
        });
    }

    async forgotPassword(email: string): Promise<any> {
        
    }

    async changePassword(oldPassword, newPassword: string): Promise<any> {
        
    }

    async changeEmail(email: string): Promise<any> {
        
    }

    async updateUser(fbUser: FBUserModel): Promise<any> {
        return new Promise((resolve, reject) => {
            console.log(fbUser)
            // this._db.list(COLLECTION_USERS).update(fbUser.uid, this.fbUser)
            this.dbRef.child(`${COLLECTION_USERS}/${fbUser.uid}`).set(fbUser)
            .then(res => {
                this.updatedUser(this.fbUser);
                resolve(this.fbUser)
            }, reject)
            .catch(error => reject())
        });
    }

    async register(data: any): Promise<any> {
        // this._progressBarService.beginLoading2();

        return new Promise<any> ((resolve, reject) => {
            this._afAuth.auth.createUserWithEmailAndPassword(data.email, data.password)
            .then((newUserCredential: firebase.auth.UserCredential) => {
                const fbUser = {
                    uid: newUserCredential.user.uid,
                    email: newUserCredential.user.email,
                    name: data.name,
                    phone: data.phone,
                    address: data.address,
                    postCode: data.postCode
                }
                this.fbUser = new FBUserModel(fbUser.uid, fbUser);
                this.dbRef.child(`${COLLECTION_USERS}/${fbUser.uid}`).set(this.fbUser);
                this.updatedUser(this.fbUser)

                resolve(this.fbUser)
            }, reject)
            .catch((err) => {
                reject();
                // this._progressBarService.endLoading2();
            })
        });        
    }

    async getUser(userId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (userId != null && userId !== '') {
                const userPath = `/${COLLECTION_USERS}/${userId}`;
                const docRef = this._db.object(userPath).valueChanges()
                .subscribe((doc: any) => {

                    let user: any;
                    user = {
                        ...doc
                    };
                    if (!user.uid){
                        resolve(false);
                    }
                    this.fbUser = new FBUserModel(user.uid, user);
                    resolve(this.fbUser);
                }, reject)
            }
            else{
                resolve(false);
            }
        });
    }

    saveHLUserToLocal(): void{
        localStorage.setItem('fbUser', JSON.stringify(this.fbUser));
    }

    updatedUser(fbUser: FBUserModel): void{
        this.fbUser = fbUser;
        this._appService.setCurUser(fbUser);
        this.onUserInfoChanged.next(this.fbUser);
        this.saveHLUserToLocal();
    }

    loadUser(): void{
        // this._progressBarService.beginLoading2();
        this.getUser(this.fbUser.uid)
        .then(fbUser => {    
            if (fbUser !== false){
                this.updatedUser(fbUser); 
                // this._progressBarService.endLoading2();
            }
        });
    }

    loadHLUserFromLocal(): void{
        const  authUser =  JSON.parse(localStorage.getItem('authUser'));
        const  fbUser =  JSON.parse(localStorage.getItem('fbUser'));
        if (authUser  !==  null)
        {
            this.fbUser.uid = authUser.uid;
            this._appService.curUser.uid = authUser.uid;
            this.loadUser();
        }
    }

    isAuthenticated(): boolean {
        const  user  =  localStorage.getItem('fbUser');
        return  user  !==  null;
    }
}
